import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/event/:title',
    name: 'Event',
    component: () => import('../views/Event.vue')
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? 'TATSING GROUP ' + to.meta.title : 'TATSING GROUP';
  store.commit('isLoading')
  next();
})

router.afterEach(() => {
  store.commit('isFinishedLoading')
})

export default router
