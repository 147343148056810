import { createStore } from 'vuex'
import axios from 'axios'

axios.defaults.baseURL = "https://tsg-api.tatsing.com.ph/api/v1"

export default createStore({
  state: {
    isLoading: false,
    errors: {},
    message: '',
    events: {},
    event: {}
  },
  mutations: {
    isLoading(state) {
      state.isLoading = true
    },
    isFinishedLoading(state) {
      state.isLoading = false
    }
  },
  actions: {
    async is_visiting() {
      await axios.post('/visit', { company_name: 'Group' }).then(() => {
        localStorage.setItem('group-visited', true)
      })
    },
    async send({state}, mail_data) {
      state.isLoading = true
      mail_data['company'] = 'Global Trading'
      await axios.post('/send', mail_data).then(() => {
        state.message = 'Sending was Successful'
        state.isLoading = false
      }).catch(error => {
        if(error.response.status === 422) {
          state.message = 'Yikes! '+error.response.data.message;
          if(error.response.data.errors)
          {
            state.errors = error.response.data.errors
          }
        }
        if(error.response.status === 429) {
          state.message = 'Yikes! Please wait for 5 minutes before sending again.';
        }
        state.isLoading = false
      })
    },
    async getAllEvent({state}, data) {
      state.isLoading = true
      await axios.get('/get-all-event', {
        params: {
            value: data.search,
            page: data.currentPage
        }
    }).then(response => {
        state.events = response.data
        state.isLoading = false
      }).catch(() => {
        state.isLoading = false
      })
    }
  },
  modules: {
  }
})
