import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "./assets/vendor/aos/aos.css"
import "./assets/vendor/bootstrap/css/bootstrap.min.css"
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css"
import "./assets/vendor/boxicons/css/boxicons.min.css"
import "./assets/vendor/glightbox/css/glightbox.min.css"
import "./assets/vendor/swiper/swiper-bundle.min.css"

import "./assets/css/style.css"

import AOS from "./assets/vendor/aos/aos.js"
import "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
import "./assets/vendor/isotope-layout/isotope.pkgd.min.js"

AOS.init()

createApp(App).use(store).use(router).mount('#app')
