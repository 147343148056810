<template>
  <div class="bg-home">
    <section id="topbar" class="d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
      </div>
    </section>

    <!-- ======= Header ======= -->
    <header id="header" class="d-flex align-items-center">
      <div class="container d-flex align-items-center justify-content-between">

        <h1 class="logo"><a href="index.php"><img src="./assets/img/svg/tsg-logo.svg" alt="tsg logo"></a></h1>

        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link scrollto active" href="/#hero">HOME</a></li>
            <li class="dropdown"><a href="/#featured-services"><span>COMPANY</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="https://www.logistics.tatsing.com.ph">TAT SING Logistics</a></li>
                <li><a href="https://www.global-trading.tatsing.com.ph">TAT SING Global Trading</a></li>
                <li><a href="https://www.consulting.tatsing.com.ph">TAT SING Consulting Group</a></li>
                <li><a href="https://www.express.tatsing.com.ph">TAT SING Express</a></li>
              </ul>
            </li>
            <li><a class="nav-link scrollto" href="/#more-about-us">ABOUT US</a></li>
            <li><a class="nav-link scrollto" href="/#events">EVENTS</a></li>
            <li><a class="nav-link scrollto" href="/#contact">CONTACT US</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->

      </div>
    </header><!-- End Header -->

    <section id="hero" class="hero">
      <div class="container" data-aos="zoom-in">

        <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <div class="hero-item">
                <h1 class="hero-title">Your Best <br/>Logistics Ally</h1>
                <p class="hero-body">
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                </p>
              </div>
            </div><!-- End testimonial item -->

            <div class="swiper-slide">
              <div class="hero-item">
                <h1 class="hero-title">Your Best <br/>Logistics Ally</h1>
                <p class="hero-body">
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                </p>
              </div>
            </div><!-- End testimonial item -->

            <div class="swiper-slide">
              <div class="hero-item">
                <h1 class="hero-title">Your Best <br/>Logistics Ally</h1>
                <p class="hero-body">
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                </p>
              </div>
            </div><!-- End testimonial item -->

            <div class="swiper-slide">
              <div class="hero-item">
                <h1 class="hero-title">Your Best <br/>Logistics Ally</h1>
                <p class="hero-body">
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                </p>
              </div>
            </div><!-- End testimonial item -->


          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div>
    </section>
    <!-- End Hero -->

  </div>
  
    <teleport to="body">
      <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4 id="contact-title" class="text-start">CONTACT US<hr class="title-border"></h4>
          <h1 class="text-start contact-header">We are happy to give <br/> the best service.</h1>
        </div>

        <div class="row">

          <div class="col-lg-6" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <!-- <iframe class="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe> -->
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.0029937696813!2d120.97204051433997!3d14.598905181033246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397ca10590326fb%3A0x75f807257b90d1b7!2sTAT%20SING%20International%20Logistics%20Corporation!5e0!3m2!1sen!2sph!4v1634600309389!5m2!1sen!2sph" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen loading="lazy"></iframe>
          </div>

          <div class="col-lg-6 card shadow-sm" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1100">
            <form @submit.prevent="send_mail">
              <div class="p-3">
                  <h2 class="text-center fs-4 mb-0">Message Us</h2>
                  <h4 class="text-center my-3"
                  :class="{'text-success': !message.includes('Yikes'), 'text-danger': message.includes('Yikes')}"
                  v-show="message">
                    {{ message }}
                  </h4>
                  <div class="card-body d-flex flex-column gap-3">
                      <input v-model="mail.full_name" type="text" class="w-100 border-0 border-bottom py-2" placeholder="Full Name" required>
                      <div class="text-danger" v-show="Object.values(errors.full_name).length">
                          <ul v-for="error in errors.full_name" :key="error">
                              <li>
                                  {{ error }}
                              </li>
                          </ul>
                      </div>
                      <input v-model="mail.email" type="email" class="w-100 border-0 border-bottom py-2" placeholder="E-mail" required>
                      <div class="text-danger" v-show="Object.values(errors.email).length">
                          <ul v-for="error in errors.email" :key="error">
                              <li>
                                  {{ error }}
                              </li>
                          </ul>
                      </div>
                      <input v-model="mail.phone" type="number" class="w-100 border-0 border-bottom py-2" placeholder="Contact No." required>
                      <div class="text-danger" v-show="Object.values(errors.phone).length">
                          <ul v-for="error in errors.phone" :key="error">
                              <li>
                                  {{ error }}
                              </li>
                          </ul>
                      </div>
                      <textarea v-model="mail.mesg" type="text" class="w-100 border-0 border-bottom py-2" placeholder="Message" rows="1" required></textarea>
                      <div class="text-danger" v-show="Object.values(errors.mesg).length">
                          <ul v-for="error in errors.mesg" :key="error">
                              <li>
                                  {{ error }}
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="ms-auto mt-3">
                  <button type="submit" class="rounded-l-sm p-2 d-flex align-items-center gap-2 text-white bg-dark border-0 ms-auto">Send Message <img src="./assets/img/icons/arrow-right.png" alt="arrow-right"></button>
              </div>
          </form>
          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->


  <!-- ======= Footer ======= -->
  <footer id="footer" class="bg-dark">
    <div class="footer-top bg-dark">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <img src="./assets/img/svg/tsg-logo.svg" alt="logo" width="95%">
            <p class="mt-4">
                10/F Unit 1019 <br>
                State Center Building, No. 333<br>
                Juan Luna St., Binondo, Manila City <br>
            </p>

            <div class="footer-links mt-md-5">
              <div class="social-links mt-3">
                <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="/#hero">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/#about">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/#events">Events</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/#contact">Contact Us</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Company</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="https://www.logistics.tatsing.com.ph">TAT SING INTERNATIONAL LOGISTICS</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://www.global-trading.tatsing.com.ph">TAT SING GLOBAL TRADING</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://www.consulting.tatsing.com.ph">TAT SING CONSULTING GROUP</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://www.express.tatsing.com.ph">TAT SING EXPRESS</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="/#hero">TAT SING GROUP</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Contact Info</h4>
            <p class="text-white">
              <img src="./assets/img/icons/email.png" alt="email icon" width="30" class="p-1"/>
              info@tatsing.com.ph
            </p>

            <p class="text-white">
              <img src="./assets/img/icons/telephone.png" alt="telephone icon" width="30" class="p-1">
                (02) 8252 1221 <br/><span style="padding-left: 2.1rem;">(02) 8452 2224</span>
              </p>

              <p class="text-white">
                <img src="./assets/img/icons/location.png" alt="location icon" width="30" class="p-1"/>
               10/F Unit 1019 State Center <br>
               <span style="padding-left: 2.1rem;">Building, No. 333 Juan Luna St.,</span> 
              <br><span style="padding-left: 2.1rem;">Binondo, Manila City</span>
              </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container py-4">
      <div class="copyright text-muted">
        Copyright &copy;2021. All Rights Reserved
      </div>
    </div>
  </footer><!-- End Footer -->

  <div id="preloader"></div>
  <a href="#" class="back-to-top mx-3 d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </teleport>
  <router-view/>
</template>

<script>
import Swiper from"./assets/vendor/swiper/swiper-bundle.min.js"
import GLightbox from "./assets/vendor/glightbox/js/glightbox.min.js"
import Waypoint from "./assets/vendor/waypoints/noframework.waypoints.js"
import Isotope from "./assets/vendor/isotope-layout/isotope.pkgd.min.js"
import AOS from "./assets/vendor/aos/aos.js"

export default {
  mounted() {
    
  if(!localStorage.getItem('group-visited')) this.$store.dispatch('is_visiting')
  
  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

  /**
   * Easy on scroll event listener 
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Navbar links active state on scroll
   */
  let navbarlinks = select('#navbar .scrollto', true)
  const navbarlinksActive = () => {
    let position = window.scrollY + 200
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }
  window.addEventListener('load', navbarlinksActive)
  onscroll(document, navbarlinksActive)

  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    if (!header.classList.contains('header-scrolled')) {
      offset -= 16
    }

    let elementPos = select(el).offsetTop
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    })
  }

  /**
   * Header fixed top on scroll
   */
  let selectHeader = select('#header')
  if (selectHeader) {
    let headerOffset = selectHeader.offsetTop
    let nextElement = selectHeader.nextElementSibling
    const headerFixed = () => {
      if ((headerOffset - window.scrollY) <= 0) {
        selectHeader.classList.add('fixed-top')
        nextElement.classList.add('scrolled-offset')
      } else {
        selectHeader.classList.remove('fixed-top')
        nextElement.classList.remove('scrolled-offset')
      }
    }
    window.addEventListener('load', headerFixed)
    onscroll(document, headerFixed)
  }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }

  /**
   * Mobile nav toggle
   */
  on('click', '.mobile-nav-toggle', function() {
    select('#navbar').classList.toggle('navbar-mobile')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })

  /**
   * Mobile nav dropdowns activate
   */
  on('click', '.navbar .dropdown > a', function(e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault()
      this.nextElementSibling.classList.toggle('dropdown-active')
    }
  }, true)

  /**
   * Scroll with ofset on links with a class name .scrollto
   */
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault()

      let navbar = select('#navbar')
      if (navbar.classList.contains('navbar-mobile')) {
        navbar.classList.remove('navbar-mobile')
        let navbarToggle = select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash)
      }
    }
  });

  /**
   * Preloader
   */
  let preloader = select('#preloader');
  if (preloader) {
    window.addEventListener('load', () => {
      preloader.remove()
    });
  }

  /**
   * Initiate glightbox
   */
  GLightbox({
    selector: '.glightbox'
  });

  /**
   * Skills animation
   */
  let skilsContent = select('.skills-content');
  if (skilsContent) {
    new Waypoint({
      element: skilsContent,
      offset: '80%',
      handler: function() {
        let progress = select('.progress .progress-bar', true);
        progress.forEach((el) => {
          el.style.width = el.getAttribute('aria-valuenow') + '%'
        });
      }
    })
  }

  

  /**
   * Porfolio isotope and filter
   */
  window.addEventListener('load', () => {
    let portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item'
      });

      let portfolioFilters = select('#portfolio-flters li', true);

      on('click', '#portfolio-flters li', function(e) {
        e.preventDefault();
        portfolioFilters.forEach(function(el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function() {
          AOS.refresh()
        });
      }, true);
    }

      });

      /**
       * Initiate portfolio lightbox 
       */
      GLightbox({
        selector: '.portfolio-lightbox'
      });

      /**
       * Portfolio details slider
       */
      new Swiper('.portfolio-details-slider', {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      });

      /**
       * Animation on scroll
       */
      window.addEventListener('load', () => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true,
          mirror: false
        })
      });
  },
  data() {
    return {
      mail: {
          full_name: '',
          email: '',
          phone: '',
          mesg: '',
      },
      message: '',
      errors: {
          full_name: {},
          email: {},
          phone: {},
          mesg: {},
      }
    }
  },
  methods: {
    async send_mail() {
      await this.$store.dispatch('send', this.mail).then(() => {
        if(Object.values(this.$store.state.errors).length) Object.assign(this.errors, this.$store.state.errors)
        this.message = this.$store.state.message
        if(this.message.includes('Yikes')) return
        else {
            this.mail = {
            full_name: '',
            email: '',
            phone: '',
            mesg: '',
            items: {}
          }
          this.errors = {
            full_name: {},
            email: {},
            phone: {},
            mesg: {},
            items: {}
          }
        }
        setTimeout(() => this.message = '', 2000)
      })
    }
  }
}

</script>