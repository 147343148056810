<template>

  <main id="main">

    <!-- ======= Featured Services Section ======= -->
    <section id="featured-services" class="featured-services">
      <div class="container" data-aos="fade-up">
  
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="icon-box-shadow bg-white d-flex align-items-stretch flex-column px-2 py-3 h-100">
              <div class="icon-box h-100 w-100" data-aos="fade-up" data-aos-delay="100">
                <div class="icon text-center"><img src="../assets/img/icons/logistics.png" alt="global trading icon" width="65"></div>
                <h4 class="title text-center"><a href="">Logistics</a></h4>
                <p class="description">
                  <ul>
                    <li>Customer Brokerage</li>
                    <li>Warehousing and Consolidation</li>
                    <li>International Freight Forwarding</li>
                </ul>
                </p>
              </div>
              <footer class="footer-card mb-4 text-end">
                <a href="https://www.logistics.tatsing.com.ph" class="btn-l-rounded btn btn-dark card-btn-link -mr-1">Learn More
                    <img src="../assets/img/icons/arrow-right.png" alt="arrow right icon" class="arrow-right ps-2"/>
                </a>
              </footer>
            </div>
          </div>
  
          <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="icon-box-shadow bg-white d-flex align-items-stretch flex-column px-2 py-3 h-100">
              <div class="icon-box h-100 w-100" data-aos="fade-up" data-aos-delay="500">
                <div class="icon text-center"><img src="../assets/img/icons/global-trading.png" alt="global trading icon" width="65"></div>
                <h4 class="title text-center"><a href="">Global Trading</a></h4>
                <p class="description">
                  <ul>
                    <li>Sourcing & Procurement</li>
                    <li>Indent Orders\Wholesale</li>
                    <li>Import and Export</li>
                </ul>
                </p>
              </div>
              <footer class="footer-card mb-4 text-end">
                <a href="https://www.global-trading.tatsing.com.ph" class="btn-l-rounded btn btn-dark card-btn-link -mr-1">Learn More
                    <img src="../assets/img/icons/arrow-right.png" alt="arrow right icon" class="arrow-right ps-2"/>
                </a>
              </footer>
            </div>
          </div>
  
          <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="icon-box-shadow bg-white d-flex align-items-stretch flex-column px-2 py-3 h-100">
              <div class="icon-box h-100 w-100" data-aos="fade-up" data-aos-delay="800">
                <div class="icon text-center"><img src="../assets/img/icons/consulting.png" alt="consulting icon" width="65"/></div>
                <h4 class="title text-center"><a href="">Consulting Group</a></h4>
                <p class="description">
                  <ul>
                    <li>Data Science and Analytics</li>
                    <li>IT Solutions and Services</li>
                    <li>Contract Review & Risk Management</li>
                </ul>
                </p>
              </div>
              <footer class="footer-card mb-4 text-end">
                <a href="https://www.consulting.tatsing.com.ph" class="btn-l-rounded btn btn-dark card-btn-link -mr-1">Learn More
                    <img src="../assets/img/icons/arrow-right.png" alt="arrow right icon" class="arrow-right ps-2"/>
                </a>
              </footer>
            </div>
          </div>
  
          <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="icon-box-shadow bg-white d-flex align-items-stretch flex-column px-2 py-3 h-100">
              <div class="icon-box h-100 w-100" data-aos="fade-up" data-aos-delay="1000">
                <div class="icon text-center"><img src="../assets/img/icons/express.png" alt="express icon" width="65"/></div>
                <h4 class="title text-center"><a href="">Express</a></h4>
                <p class="description">
                  <ul>
                    <li>Fast Delivery</li>
                    <li>Safe handling of cargo</li>
                    <li>Trustworthy truckers</li>
                </ul>
                </p>
              </div>
              <footer class="footer-card mb-4 text-end">
                <a href="https://www.express.tatsing.com.ph" class="btn-l-rounded btn btn-dark card-btn-link -mr-1">Learn More
                    <img src="../assets/img/icons/arrow-right.png" alt="arrow right icon" class="arrow-right ps-2 "/>
                </a>
              </footer>
            </div>
          </div>
  
        </div>
  
      </div>
    </section><!-- End Featured Services Section -->

    <!-- ======= About Section ======= -->
    <div class="about-bg">
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column" data-aos="fade-up" data-aos-delay="100">
              <h4 class="about-us-title">ABOUT US
                <hr class="title-border">
              </h4>
              <h1 class="text-dark about-title">Beyond Average<br/> Freight Forwarders</h1>
              <p class="about-us-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <div class="mt-5 read-more">
              <a href="#" class="btn btn-dark text-white p-3">Read More
                  <img src="../assets/img/icons/arrow-right.png" alt="arrow right icon" class="arrow-right ps-2"/>
              </a>
            </div>
            </div> 

            <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
              <img src="../assets/img/tsg/about-us-section.png" class="img-fluid" alt="">
            </div>
          </div>

        </div>
      </section><!-- End About Section -->

      <!-- ======= More About Us Section ======= -->
      <section id="more-about-us" class="more-about-us">
          <div class="container more-about-us-section" data-aos="fade-up">
    
            <div class="section-title">
              <h4 class="text-start text-white more-about-us-title">MORE ABOUT US<hr class="title-border"></h4>
              <h1 class="text-start text-white more-about-us-header">Why we choose us?</h1>
            </div>
    
            <div class="row mt-5 card-holder">
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch more-about-us-card-holder" data-aos="zoom-in" data-aos-delay="100">
                <div class="more-about-us-icon-box">
                  <div class="icon"><img src="../assets/img/icons/offers.png" alt="offers icon" class="card-icon"/></div>
                  <h4 class="pt-3">Offers best services and experiences</h4>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
                </div>
              </div>
    
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch more-about-us-card-holder" data-aos="zoom-in" data-aos-delay="300">
                <div class="more-about-us-icon-box">
                  <div class="icon"><img src="../assets/img/icons/network.png" alt="offers icon" class="card-icon"/></div>
                  <h4 class="pt-3">We have engaging employees</h4>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
                </div>
              </div>
    
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch more-about-us-card-holder" data-aos="zoom-in" data-aos-delay="300">
                <div class="more-about-us-icon-box">
                  <div class="icon"><img src="../assets/img/icons/connects.png" alt="offers icon" class="card-icon"/></div>
                  <h4 class="pt-3">Connects truckers and clients</h4>
                  <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
                </div>
              </div>
            </div>
    
          </div>
      </section>
    </div><!-- End More About Us Section -->

    <!-- ======= Testimonials Section ======= -->
    <section id="testimonials" class="testimonials">
      <div class="container" data-aos="zoom-in">

        <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <div class="testimonial-item">
                <img src="../assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
                <h3>Saul Goodman</h3>
                <h4>Ceo &amp; Founder</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div><!-- End testimonial item -->

            <div class="swiper-slide">
              <div class="testimonial-item">
                <img src="../assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
                <h3>Sara Wilsson</h3>
                <h4>Designer</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div><!-- End testimonial item -->

            <div class="swiper-slide">
              <div class="testimonial-item">
                <img src="../assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
                <h3>Jena Karlis</h3>
                <h4>Store Owner</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div><!-- End testimonial item -->

            <div class="swiper-slide">
              <div class="testimonial-item">
                <img src="../assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
                <h3>Matt Brandon</h3>
                <h4>Freelancer</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div><!-- End testimonial item -->

            <div class="swiper-slide">
              <div class="testimonial-item">
                <img src="../assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
                <h3>John Larson</h3>
                <h4>Entrepreneur</h4>
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div><!-- End testimonial item -->

          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div>
    </section><!-- End Testimonials Section -->

    <!-- ======= Events Section ======= -->
    <section id="events" class="events">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4 id="events-header" class="text-start">EVENTS<hr class="title-border"></h4>
          <h1 class="text-start events-header">Take a look on our newest <br/>events right away.</h1>
        </div>

        <div v-show="Object.values(events).length != 0">
          <div class="d-flex justify-content-end w-100 p-2" v-if="totalPages > 1">
            <form @submit.prevent="find">
              <input v-model="data.search" type="text" class="ms-auto">
              <button type="submit">Search</button>
            </form>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-3" v-for="event in events" :key="event">
              <div :id="event.title" class="icon-box w-100" data-aos="zoom-in">
                <img :src="location + event.id + '/' + event.file_names[0]" class="card-img-top" height="250" width="200" alt="Event Image">
                <p class="fs-6 mt-3">{{ format_date(event.event_date_time) }}</p>
                <p class="fs-6 mt-3">{{ event.location }}</p>
                <h6 class="event-text p-3">{{ event.title }}</h6>
                <p class="text-muted mb-4 text-uppercase event-footer">{{ event.description }}</p>
              </div>
            </div>
          </div>
          
          <Pagination class="mx-2" v-if="totalPages > 1" :totalPages="totalPages" :perPage="10" :currentPage="data.currentPage" :maxVisibleButtons="4" @pagechanged="onPageChange" />
        </div>
        <div v-show="Object.values(events).length == 0">
          <h3 class="text-center">Sorry, No Event Happening</h3>
        </div>

      </div>
    </section><!-- End Events Section -->
    </main>
</template>

<script>
import Swiper from"@/assets/vendor/swiper/swiper-bundle.min.js"
import Pagination from "@/components/Pagination.vue";
import { format } from 'date-fns/fp'

export default {
  name: 'Home',
  components: {
    Pagination,
  },
  data() {
    return {
      events: {},
      location: 'https://tsg-api.tatsing.com.ph/api/v1/public/storage/events/',
      data: {
        search: '',
        currentPage: 1
      },
      totalPages: 1
    }
  },
  async mounted() {
    /**
     * Testimonials slider
     */
    new Swiper('.testimonials-slider', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });

    await this.$store.dispatch('getAllEvent', this.data).then(() => {
      Object.assign(this.events, this.$store.state.events.data)
    })

    Object.values(this.events).forEach((element, key) => {
      if(key % 1 == 0) document.getElementById(element.title).setAttribute('data-aos-delay', 300)
      if(key % 2 == 0) document.getElementById(element.title).setAttribute('data-aos-delay', 200)
      if(key % 3 == 0) document.getElementById(element.title).setAttribute('data-aos-delay', 100)
    })
  },
  methods: {
    onPageChange(page) {
      this.data.currentPage = page;
      this.$store.dispatch('event/getEvents', this.data);
    },
    async find() {
      await this.$store.dispatch('getAllEvent', this.data).then(() => {
        this.events = this.$store.state.events.data
        this.totalPages = this.$store.state.events.meta.last_page
      })
    },
    format_date(date) {
      return format('MMM. dd, yyyy eee | hh:mm a', new Date(date))
    }
  }
}
</script>
